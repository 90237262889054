@import "~@flaticon/flaticon-uicons/css/all/all";

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #D4FFF6;
}

body {
    width: 100vw;
    height: 100vh;
}

.ockerAccent {
    color: #B88428;
}

.Background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: url('images/background.jpeg');
    background-size: cover;
    background-position: center;
    filter: blur(5px) brightness(65%);
    z-index: -1;
}

.Spacer {
    width: 100vw;
    height: 8vh;
}

.E404, .UnderConstruction {
    margin-top: -8vh;
    height: 92vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.E404 i, .UnderConstruction i {
    font-size: 14vh;
}

.E404 h1, .UnderConstruction h1 {
    font-size: 6vh;
    margin: 0 5vw 0 5vw;
}

.NavBar {
    position: fixed;
    background-color: rgba(39, 55, 77, 100%);
    border-bottom: 5px solid rgb(74, 104, 146);
    width: 100vw;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.NavBar ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    width: 90vw;
    /* column-gap: 5vw; not supported in safari 14 or lower :| workaround to manually add margins below */
    font-size: 2vh;
}

.NavBar a {
    text-decoration: none;
}

.NavBar a:hover {
    color: #DDE6ED;
}

.NavBar a.active:hover {
    color: #79a8b1;
}

.NavBar a.active {
    color: #9AD7E2;
}

.NavBar .home {
    margin-right: auto;
}

.NavBar .courses, .NavBar .about {
    margin-right: 5vw;
}

.Home {
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.Home .Title {
    margin-top: 1vh;
    width: 90vw;
}

.Home .Title text {
    fill: #D4FFF6;
    font-size: 13.5vh;
    text-anchor: middle;
}

.Home .Title #subtitle {
    font-size: 2.5vh;
    text-align: center;
}

.Home .ImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3vh;
    width: 100vw;
}

.Home .ImageContainer div {
    display: flex;
    flex-direction: column;
    text-align: right;
    width: 25vw;
    margin-right: -3vw;
}

.Home .ImageContainer img {
    max-height: 60vh;
    max-width: 50vw;
}

.Home .ImageContainer img ~ div {
    text-align: left;
    margin-right: 0;
    margin-left: -3vw;
}

.Home .ImageContainer div p, .Home .ImageContainer div a {
    margin: 2vh 0;
    font-size: 2.5vh;
}

.Home .ImageContainer img ~ div a:last-child {
    margin-bottom: 5vh;
    font-size: 3.5vh;
}

.Home .ImageContainer img ~ div a {
    margin: 1vh 0;
    font-size: 2.5vh;
}

.Home .ImageContainer img ~ div a span {
    color: #9AD7E2;
    text-decoration: none;
    border-bottom: 2px solid #B88428;
}

.Home .ImageContainer img ~ div a span:hover {
    color: #79A8B1;
    cursor: pointer;
    border-bottom: none;
}

.Home .Endtle {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1vh 0 3vh 0;
}

.Home .Endtle p {
    text-align: center;
    font-size: 3.5vh;
    margin: 1vh 0;
}

.Home .Endtle p:nth-child(2) {
    font-size: 4vh;
}

.Home .Endtle p:last-child {
    margin-top: 3vh;
    font-size: 3vh;
}

.Home .Endtle p:last-child a {
    text-decoration: none;
}

.Home .Endtle p:last-child a span {
    color: #9AD7E2;
    text-decoration: none;
    border-bottom: 2px solid #B88428;
}

.Home .Endtle p:last-child a span:hover {
    color: #79A8B1;
    cursor: pointer;
    border-bottom: none;
}

.Courses {
    width: 100vw;
    height: 92vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.Courses .Title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1vh;
    width: 90vw;
    margin-bottom: 3vh;
}

.Courses .Title p {
    font-size: 7vh;
    text-align: center;
}

.Courses .Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95vw;
}

.Courses .Content > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Courses .Content > div > p:first-child {
    font-size: 3vh;
    text-align: center;
}

.Courses .Content > div > p:nth-child(2) {
    font-size: 2.5vh;
    text-align: center;
    margin: 1vh 0 3vh 0;
}

.Courses .Content > div .CourseBox {
    display: flex;
    flex-direction: column;
    width: 80vw;
    background-color: rgba(39, 55, 77, 100%);
    border: 3px solid rgb(74, 104, 146);
    border-radius: 12px;
    margin-bottom: 5vh;
    padding: 1vh 3vw;
    text-align: left;
}

.Courses .Content > div .CourseBox > p:first-child {
    font-size: 3.5vh;
    text-align: left;
}

.Courses .Content > div .CourseBox div {
    overflow: scroll;
}

.Courses .Content > div .CourseBox div p {
    font-size: 2.5vh;
    margin: 1vh 0;
    text-align: left;
}

.Courses .Content > div p:last-child {
    text-align: center;
    font-size: 2.5vh;
    margin: -3.5vh 0 5vh 0;
}

.Courses .Content > div p:last-child a {
    text-decoration: none;
}

.Courses .Content > div p:last-child a span {
    color: #9AD7E2;
    border-bottom: 2px solid #B88428;
}

.Courses .Content > div p:last-child a span:hover {
    color: #79A8B1;
    border-bottom: none;
}

.About {
    width: 100vw;
    height: 92vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.About .Title {
    display: flex;
    justify-content: center;
    margin-top: 1vh;
    width: 90vw;
}

.About .Title p {
    font-size: 7vh;
}

.About .Content {
    display: flex;
    flex-direction: column;
    margin-top: 4vh;
}

.About .Content div {
    display: flex;
    flex-direction: column;
    width: 75vw;
    margin-bottom: 2vh;
}

.About .Content div p:first-child {
    font-size: 3.5vh;
}

.About .Content div img {
    width: 75vw;
    align-self: center;
    border-radius: 20px;
    margin: 1vh 0;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.About .Content div p:nth-child(3) {
    font-size: 3vh;
    text-align: center;
    margin-bottom: 0.5vh;
}

.About .Content div p:last-child {
    font-size: 2.5vh;
    text-align: left;
}

.About .Content ul {
    width: 75vw;
    align-self: center;
    font-size: 2.5vh;
    margin-bottom: 3vh;
}

.About .Content ul p:nth-child(1), .About .Content ul p:nth-child(2) {
    display: none;
}

.About .Content ul p:nth-child(3) {
    font-size: 3vh;
    text-align: center;
}

.About .Content ul li {
    list-style: none;
    margin: 0.5vh 0;
}

.About .Content ul li::before {
    content: "+";
    color: #B88428;
    margin-right: 1vw;
    display: inline-block;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); 
}

.Contact {
    width: 100vw;
    height: 92vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.Contact .Title {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
}

.Contact .Title p:first-child {
    font-size: 7vh;
}

.Contact .Title p:last-child {
    font-size: 3vh;
    margin: 1vh 0;
}

.Contact .Content {
    display: flex;
    flex-direction: column;
    width: 90vw;
    align-items: center;
    justify-content: center;
    margin-bottom: auto;
}

.Contact > p {
    margin-top: auto;
    font-size: 4vh;
}

.Contact .Content div p {
    font-size: 3vh;
    margin-bottom: 0;
}

.Contact .Content i {
    font-size: 10vh;
    margin-right: 5vw;
    line-height: 10vh;
}

.Contact .Content .ContactEntry {
    display: flex;
    width: 80vw;
    margin: 2vh 0;
}

.Contact .Content .ContactEntry div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Contact .Content .ContactEntry a {
    text-decoration: none;
}

.Contact .Content .ContactEntry a span {
    color: #9AD7E2;
    border-bottom: 2px solid #B88428;
}

.Contact .Content .ContactEntry a span:hover {
    color: #79A8B1;
    border-bottom: none;
}

.Contact img {
    max-width: 90vw;
    max-height: 40vh;
    border-radius: 12px;
    margin: auto 0;
}

.Contact > p:last-child {
    font-size: 2.5vh;
    margin-bottom: 2vh;
}

.Contact > p:last-child a {
    text-decoration: none;
}

.Contact > p:last-child a span {
    color: #9AD7E2;
    border-bottom: 2px solid #B88428;
}

.Contact > p:last-child a span:hover {
    color: #79A8B1;
    border-bottom: none;
}

.Popup {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(3px) brightness(65%);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.Popup .Content {
    display: flex;
    flex-direction: column;
    width: 80vw;
    height: 60vh;
    background-color: rgba(39, 55, 77, 100%);
    border: 3px solid rgb(74, 104, 146);
    border-radius: 12px;
    padding: 1vh 3vw;
}

.Popup .Content p {
    font-size: 2.5vh;
    overflow: scroll;
}

.Popup .Content div {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2vh;
}

.Popup .Content div button {
    background-color: #9AD7E2;
    width: 12vw;
    line-height: 4vh;
    font-size: 4vh;
    border: 3px solid #9AD7E2;
    border-radius: 12px;
    margin-left: auto;
    transition: border-color 0.2s ease-in-out;
    padding: 0 0 1px 2px;
}

.Popup .Content div button:hover {
    border: 3px solid rgb(74, 104, 146);
}

.Popup .Content div button span {
    display: inline-block;
    rotate: 45deg;
    color: rgba(39, 55, 77, 100%);
}

.Popup .Content div p {
    font-size: 3.5vh;
    margin-left: 0;
}

@media screen and (min-height: 740px) {
    .Home .Title text {
        font-size: 12vh;
    }
}

@media screen and (min-height: 900px) {
    .Home .Title text {
        font-size: 9vh;
    }

    .Home .Title p {
        margin-top: -1vh;
    }
}

@media screen and (min-width: 800px) {
    .Home .Title #subtitle {
        font-size: 3.5vh;
    }

    .Home .ImageContainer div {
        margin-right: 1vw;
    }

    .Home .ImageContainer img ~ div {
        margin-left: 1vw;
    }

    .Home .ImageContainer div p, .Home .ImageContainer div a {
        font-size: 3vh !important;
    }

    .Home .ImageContainer img ~ div a:last-child {
        font-size: 4.5vh !important;
    }

    .Home .Endtle p {
        font-size: 4.5vh;
    }

    .Home .Endtle p:nth-child(2) {
        font-size: 5.5vh;
    }

    .Home .Endtle p:last-child {
        font-size: 4vh;
    }

    .Courses .Title p {
        font-size: 9vh;
    }

    .Courses .Content {
        flex-direction: row;
        justify-content: center;
        width: 95vw;
        align-items: flex-start;
    }
    
    .Courses .Content > div {
        width: 40vw;
        height: 70vh;
        margin: 0 auto;
        overflow: scroll;
    }

    .Courses .Content > div > p:first-child {
        font-size: 4vh;
    }

    .Courses .Content > div > p:nth-child(2) {
        font-size: 3vh;
    }

    .Courses .Content div .CourseBox {
        width: 40vw;
        padding: 1vh 1vw;
        margin-bottom: 5vh;
    }

    .About .Title p {
        font-size: 9vh;
    }

    .About .Content {
        flex-direction: row;
    }

    .About .Content div {
        width: 40vw;
        align-self: flex-start;
    }

    .About .Content div img {
        height: 45vh;
        width: auto;
        align-self: flex-start;
    }

    .About .Content div p:nth-child(3), .About .Content div p:nth-child(4) {
        display: none;
    }

    .About .Content ul {
        width: 40vw;
        align-self: flex-start;
    }

    .About .Content ul p:nth-child(1) {
        display: block;
        font-size: 3.5vh;
        margin-bottom: 0.5vh;
    }

    .About .Content ul p:nth-child(2) {
        display: block;
        font-size: 2.5vh;
        margin-bottom: 1vh;
    }

    .About .Content ul p:nth-child(3) {
        font-size: 3.5vh;
        text-align: left;
    }

    .Contact .Title p:first-child {
        font-size: 9vh;
    }

    .Contact .Title p:last-child {
        font-size: 4.5vh;
    }

    .Contact > p {
        font-size: 5vh;
    }

    .Contact .Content {
        flex-direction: row;
        padding: 0 5vw;
    }

    .Contact .Content .ContactEntry:last-child {
        justify-content: flex-end;
    }

    .Contact .Content .ContactEntry:last-child div {
        text-align: right;
    }

    .Contact .Content .ContactEntry:last-child i {
        margin: 0 0 0 5vw;
        order: 1;
    }

    .Popup .Content {
        padding: 1vh 1vw;
    }

    .Popup .Content div button {
        width: 6vw;
        height: 5vh;
    }

    .Popup .Content div p {
        font-size: 5vh;
    }
}

@media screen and (min-width: 1000px) {
    .Home .Title #subtitle {
        font-size: 4.5vh;
    }

    .Home .Title p {
        margin-top: -3vh;
    }

    .Home .ImageContainer div {
        margin-right: 3vw;
    }

    .Home .ImageContainer img ~ div {
        margin-left: 3vw;
    }

    .Home .ImageContainer div p, .Home .ImageContainer div a {
        font-size: 3.5vh !important;
    }

    .Home .ImageContainer img ~ div a:last-child {
        font-size: 5.5vh !important;
    }

    .Home .Endtle p {
        font-size: 5vh;
    }

    .Home .Endtle p:nth-child(2) {
        font-size: 6vh;
    }

    .Home .Endtle p:last-child {
        font-size: 4vh;
    }

    .Courses .Title p {
        font-size: 11vh;
    }

    .About .Title p {
        font-size: 11vh;
    }

    .About .Content div img {
        height: 55vh;
    }

    .Contact .Title p:first-child {
        font-size: 11vh;
    }

    .Contact .Content {
        padding: 0 10vw;
    }
}